<template>
	<div>
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<a-row>
					<a-form-item class="ui-form__item" label="导航标题" name="title">
						<a-input v-model:value="formState.title" placeholder="请输入导航标题"></a-input>
					</a-form-item>
					<a-form-item label="状态" name="isDisabled" class="ui-form__item">
						<a-select v-model:value="formState.isDisabled" placeholder="请选择状态" allow-clear
							style="width: 200px;">
							<a-select-option :value="0">启用</a-select-option>
							<a-select-option :value="1">禁用</a-select-option>
						</a-select>
					</a-form-item>
				</a-row>
				<a-row>
					<a-col :span="18">
						<a-button v-permission="['performance_manage_navigation_add']" type="primary"
							@click="onAddNavigation">新增导航</a-button>
					</a-col>
					<a-col :span="6" style="text-align: right;">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>

			<div style="margin-top: 20px;">
				<a-table rowKey="id" :pagination="pagination" :columns="columns" :dataSource="list">
					<template #bodyCell="{ column, record }">
						<template v-if="column.key === 'isDisabled'">
							<a-tag v-if="!record.isDisabled" color="#87d068">启用</a-tag>
							<a-tag v-if="record.isDisabled" color="#f50">禁用</a-tag>
						</template>
						<template v-if="column.key === 'action'">
							<a-dropdown :trigger="['click', 'hover']">
								<a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
								</a-button>
								<template #overlay>
									<a-menu>
										<div v-permission="['performance_manage_navigation_edit']"
											@click="onEdit(record)">
											<a-menu-item>
												编辑
											</a-menu-item>
										</div>
										<div v-permission="['performance_manage_navigation_disabled']"
											v-if="record.isDisabled" @click="onDisabled(record)">
											<a-menu-item>
												启用
											</a-menu-item>
										</div>
										<div v-permission="['performance_manage_navigation_disabled']" v-else
											@click="onDisabled(record)">
											<a-menu-item>
												禁用
											</a-menu-item>
										</div>
										<div v-permission="['performance_manage_navigation_delete']"
											@click="onDelete(record)">
											<a-menu-item>
												刪除
											</a-menu-item>
										</div>
									</a-menu>
								</template>
							</a-dropdown>
						</template>
					</template>
				</a-table>
			</div>

			<a-modal v-model:visible="showModal" :title="modelRef.id ? '修改导航' : '添加导航'" @ok="onSubmit"
				@cancel="onCancel">
				<a-spin :spinning="loading">
					<a-form ref="addForm" scrollToFirstError :model="modelRef" name="addForm" :labelCol="{ span: 5 }"
						:wrapperCol="{span: 16}">
						<a-form-item label="导航标题" name="title" :rules="[{ required: true, message: '必填项不允许为空' }]">
							<a-input v-model:value="modelRef.title" placeholder="请输入导航标题" />
						</a-form-item>
						<a-form-item label="演出分类" name="classifyId" :rules="[{ required: true, message: '必填项不允许为空' }]">
							<preformClassify v-model:value="modelRef.classifyId"></preformClassify>
						</a-form-item>
						<a-form-item label="优先级" name="sort" extra="优先级越高越靠前，不填则为0">
							<a-input-number v-model:value="modelRef.sort" :min="0" :precision="0" placeholder="请输入优先级" />
						</a-form-item>
						<a-form-item label="状态" name="isDisabled">
							<a-switch v-model:checked="modelRef.isDisabled" checked-children="启用"
								un-checked-children="禁用" />
						</a-form-item>
					</a-form>
				</a-spin>
			</a-modal>
		</a-spin>
	</div>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import preformClassify from '@/views/performance/components/classify/index.vue';
	import {
		deletePerformNavigation,
		getPerformNavigationList,
		savePerformNavigation,
		updatePerformNavigation,
	} from "@/service/modules/perform";
	export default {
		components: {
			Icon,
			preformClassify
		},
		data() {
			return {
				loading: false,
				searchData: {},
				showModal: false,
				modelRef: {},
				formState: {},
				list: [],
				columns: [{
					title: '导航标题',
					dataIndex: 'title',
				}, {
					title: '导航分类',
					dataIndex: 'classifyName',
				}, {
					title: '导航优先级',
					dataIndex: 'sort',
				}, {
					title: '状态',
					key: 'isDisabled',
				}, {
					title: '操作',
					key: 'action',
					width: 300
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					pageSizeOptions: ['10', '20', '50', '100', '500'],
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
			}
		},
		created() {
			this.getData();
		},
		methods: {
			reset() {
				this.$refs.form.resetFields();
				this.onSearch();
			},
			onSearch() {
				this.pagination.current = 1;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.getData();
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getPerformNavigationList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						...this.searchData
					})
					this.loading = false;
					if (ret.code === 200) {
						this.list = ret.data.list;
						this.pagination.total = ret.data.totalCount;
					}
				} catch (e) {
					this.loading = false;
				}
			},
			onAddNavigation() {
				this.id = 0;
				this.isEdit = false;
				this.showModal = true;
				this.modelRef = {
					isDisabled: true
				};
			},
			onEdit(item) {
				this.id = item.id;
				this.isEdit = true;
				this.showModal = true;
				this.modelRef = JSON.parse(JSON.stringify(item));
				this.modelRef.isDisabled = this.modelRef.isDisabled ? false : true;
			},
			async onSubmit() {
				if (!this.modelRef.title) {
					this.$message.error('导航标题不能为空');
					return;
				}
				if (!this.modelRef.classifyId) {
					this.$message.error('演出分类');
					return;
				}
				let postData = JSON.parse(JSON.stringify(this.modelRef));
				postData.isDisabled = postData.isDisabled ? 0 : 1;
				this.loading = true;
				try {
					let ret;
					if (this.isEdit) {
						postData.id = this.id;
						ret = await updatePerformNavigation(postData);
					} else {
						ret = await savePerformNavigation(postData);
					}
					this.loading = false;
					if (ret.code === 200) {
						this.$message.success('操作成功');
						this.getData();
						this.showModal = false;
					}
				} catch (e) {
					this.loading = false;
				}
			},
			onCancel() {
				this.modelRef = {};
			},
			onDelete(item) {
				this.$confirm({
					title: '提示',
					content: '确定删除吗？',
					onOk: async () => {
						this.loading = true;
						try {
							let ret = await deletePerformNavigation({
								id: item.id
							});
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('操作成功');
								this.getData();
							}
						} catch (e) {
							this.loading = false;
						}
					}
				})
			},
			onDisabled(item) {
				let postData = JSON.parse(JSON.stringify(item));
				postData.id = item.id;
				postData.isDisabled = item.isDisabled ? 0 : 1
				this.$confirm({
					title: '提示',
					content: '确认要' + (item.isDisabled ? '启用' : '禁用') + '吗？',
					onOk: async () => {
						this.loading = true;
						try {
							let ret = await updatePerformNavigation(postData)
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success((item.isDisabled ? '启用' : '禁用') + '成功');
								this.getData();
							}
						} catch (e) {
							this.loading = false;
						}
					}
				})
			},
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>